import { gsap } from 'gsap';

document.addEventListener('DOMContentLoaded', () => {
	const button = document.getElementById('offcanvas-toggler');
	const offcanvas = document.getElementById('offcanvas-main');
	const parentItemLink = offcanvas.querySelectorAll('.menu-item-has-children > a');

	gsap.set(offcanvas, { xPercent: 100 });

	button.addEventListener('click', () => {
		const isOpen = offcanvas._gsap && offcanvas._gsap.xPercent === 0;

		isOpen ? button.classList.remove('active') : button.classList.add('active');
		isOpen ? document.body.classList.remove('offcanvas-open') : document.body.classList.add('offcanvas-open');

		gsap.to(offcanvas, {
			xPercent: isOpen ? 100 : 0,
			duration: 0.3,
			ease: "power2.out"
		});
	});
	
	parentItemLink.forEach((item) => {
		item.addEventListener('click', (e) => {
			if (! item.classList.contains('active')) {
				e.preventDefault();
				item.classList.add('active');
				const submenu = item.nextElementSibling;
				const icon = item.querySelector('i');

				submenu.classList.remove('hidden');

				gsap.to(icon, {
					rotate: 90,
					duration: 0.3,
					ease: "circ.out"
				});
			}
		});
	});
});
